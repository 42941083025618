import { getUpdatedUrl } from "../../utils/";
import PropTypes from "prop-types";
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";

const PaginationC = (props): ReactElement => {
  const { first, totalElements, numberOfElements, limit, currentPageNumber } =
    props;
  console.log("currentPageNumber", currentPageNumber);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setitemsPerPage] = useState<number>(limit || 16);
  const navigate = useNavigate();

  const handleClick = (page: any, pageSize) => {
    setCurrentPage(Number(page));
    setitemsPerPage(Number(pageSize));
    navigate(
      getUpdatedUrl("page", `${page - 1 >= 0 ? page - 1 : currentPage}`)
    );
    navigate(
      getUpdatedUrl("limit", `${pageSize >= 0 ? pageSize : itemsPerPage}`)
    );
  };

  // useEffect(() => {
  //   if (currentPageNumber === 0) {
  //     handleClick(0, itemsPerPage);
  //   }
  // }, [currentPageNumber]);

  const pages = [];
  const queryParams = new URLSearchParams(window.location.search);
  const page = queryParams.get("page");
  let start = 1;
  let end = 10;
  if (page === null) {
    start = 1;
    end = 10;
  } else {
    const floorValue = Math.floor(parseInt(page) / 10);
    const ceilValue = Math.ceil(parseInt(page) / 10);
    start = 10 * floorValue + 1;
    end = Math.min(10 * ceilValue, totalElements);
    if (start > end) end = Math.min(start + 9, totalElements);
  }

  for (let i = 1; i <= Math.ceil((totalElements || 0) / itemsPerPage); i++) {
    pages.push(i);
  }

  useEffect(() => {
    if (first) {
      setCurrentPage(1);
    }
  }, [first]);

  console.log(`Showing ${totalElements} items`);

  return (
    <div
      className={
        // totalElements &&
        "bg-white text-xs px-4 py-3 border-t border-gray-200 sm:px-6 w-full"
      }
    >
      {/* {totalElements > itemsPerPage && ( */}
      <Pagination
        responsive
        total={totalElements}
        showTotal={(total, range) =>
          `Showing ${range[0] || 0} to ${range[1] || total || 0} (${total} item)`
        }
        pageSizeOptions={[16, 32, 50, 100]}
        defaultPageSize={itemsPerPage}
        defaultCurrent={currentPageNumber + 1}
        onChange={handleClick}
        // hideOnSinglePage
      />
      {/* )} */}
    </div>
  );
};

PaginationC.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  numberOfElements: PropTypes.number,
  currentPageNumber: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  orderTable: PropTypes.string,
};
export default PaginationC;
