export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const SIDEBAR_COLLAPSE = "SIDEBAR_COLLAPSE";
export const SET_SHOP_INFO = "SET_SHOP_INFO";
export const SET_BRAND_INFO = "SET_BRAND_INFO";
export const SET_CATEGORY_INFO = "SET_CATEGORY_INFO";
export const SET_MERCHANT_INFO = "SET_MERCHANT_INFO";
export const SET_CAMPAIGN_INFO = "SET_CAMPAIGN_INFO";
export const SET_DRIVER_INFO = "SET_DRIVER_INFO";
export const SET_CORPORATE_INFO = "SET_CORPORATE_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_FEEDBACK_INFO = "SET_FEEDBACK_INFO";
export const SET_MALL_INFO = "SET_MALL_INFO";
export const LOGOUT = "LOGOUT";
