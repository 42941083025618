import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { DataContextProvider } from "./contexts/DataContextProvider";

import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={store}>
    <DataContextProvider>
      <ContextProvider>
        <PersistGate persistor={persistor}>
          {/* <ConfigProvider
          theme={{
            components: {
              Radio: {
                colorPrimary: "#FF5A3C",
              },
            },
          }}
        > */}
          <App />
          {/* </ConfigProvider> */}
        </PersistGate>
      </ContextProvider>
    </DataContextProvider>
  </Provider>,
  document.getElementById("root")
);
