import React from 'react'

const Button = ({
  bgColor,
  width = '',
  text,
  color,
  size = '',
  borderRadius,
  customclass = '',
  shadow = 'hover:drop-shadow-xl',
  onClick,
}) => {
  return (
    <button
      type="button"
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`text-${size} p-3 ${shadow} ${customclass}`}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

export default Button
