import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar, Sidebar, ThemeSettings } from "./components";
import {
  Dashboard,
  Orders,
  Calendar,
  Partners,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Line,
  Login,
  NotFound,
} from "./pages";

import { useStateContext } from "./contexts/ContextProvider";
import "./App.css";
import Services from "./pages/Services";
import { useDataContext } from "./contexts/DataContextProvider";

const App = () => {
  const {
    themeSettings,
    activeMenu,
    setthemeSettings,
    currentColor,
    currentMode,
  } = useStateContext();

  const { token } = useDataContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <Routes>
          {!token ? (
            <>
              <Route path="*" element={<Login />} />
              <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
            </>
          ) : (
            <>
              <Route path="*" element={<NotFound />} />

              <Route path="/" element={<Dashboard />} />
              <Route path="/data" element={<Dashboard />} />

              <Route path="/orders" element={<Orders />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/services" element={<Services />} />
              <Route path="/customers" element={<Customers />} />

              {/* 
                    <Route path="/kanban" element={<Kanban />} />
                    <Route path="/editor" element={<Editor />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/color-picker" element={<ColorPicker />} />

                    <Route path="/line" element={<Line />} />
                    <Route path="/area" element={<Area />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/financial" element={<Financial />} />
                    <Route path="/color-mapping" element={<ColorMapping />} />
                    <Route path="/pyramid" element={<Pyramid />} />
                    <Route path="/stacked" element={<Stacked />} /> 
                    */}
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
