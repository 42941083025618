import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar, Sidebar, ThemeSettings } from "../components";
import {
  Dashboard,
  Orders,
  Calendar,
  Partners,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Line,
  Login,
  NotFound,
} from "../pages";

import { useStateContext } from "../contexts/ContextProvider";

const Layout = ({ children }) => {
  const {
    themeSettings,
    activeMenu,
    setthemeSettings,
    currentColor,
    currentMode,
  } = useStateContext();

  const { token } = useSelector((state) => (state as any)?.authReducer);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-5 bottom-5" style={{ zIndex: "1000" }}>
            <TooltipComponent content="settings" position="TopCenter">
              <button
                type="button"
                className="text-3xl hover:drop-shadow-xl p-3 hover:bg-light-gray text-white"
                style={{ background: currentColor, borderRadius: "50%" }}
                onClick={() => setthemeSettings(true)}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div
              className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white"
              style={{ zIndex: "1000" }}
            >
              <Sidebar />
            </div>
          ) : (
            <div
              className="w-0 dark:bg-secondary-dark-bg"
              style={{ zIndex: "1000" }}
            >
              <Sidebar />
            </div>
          )}
          <div
            className={`
            dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${
              activeMenu ? "md:ml-72" : " flex-2"
            }
            `}
          >
            <div
              className="fixed md:sticky top-0 bg-white dark:bg-main-dark-bg navbar w-full"
              style={{ zIndex: "10" }}
            >
              <Navbar />
            </div>

            <div>
              {themeSettings && <ThemeSettings />}
              {children}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Layout;
