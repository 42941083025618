import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { BsChatLeft } from "react-icons/bs";
import { RiNotification3Line } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import avatar from "../data/avatar.jpg";
import { Cart, Chat, Notification, UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { useSelector } from "react-redux";

const NavButton = ({ title, customFunc, icon, color, dotColor = "" }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {
    setactiveMenu,
    isClicked,
    handleClick,
    screenSize,
    setscreenSize,
    currentColor,
  } = useStateContext();
  const data = useSelector((state) => (state as any)?.authReducer);

  useEffect(() => {
    const handleResize = () => setscreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setactiveMenu(false);
    } else {
      setactiveMenu(true);
    }
  }, [screenSize]);

  return (
    <>
      <div className="flex justify-between p-2  md:mx-6 relative">
        <NavButton
          title="Menu"
          customFunc={() => setactiveMenu((prevActiveMenu) => !prevActiveMenu)}
          color={currentColor}
          icon={<AiOutlineMenu />}
        />

        <div className="flex">
          {/* <NavButton
            title="Cart"
            customFunc={()=> handleClick('cart') }
            color={currentColor} 
            icon={<FiShoppingCart />}
          /> */}

          {/* <NavButton
            title="Chat"
            dotColor="#FF5A3C"
            customFunc={()=> handleClick('chat') }
            color={currentColor} 
            icon={<BsChatLeft />}
          /> */}

          <NavButton
            title="Notifications"
            dotColor="#FF5A3C"
            customFunc={() => handleClick("notification")}
            color={currentColor}
            icon={<RiNotification3Line />}
          />

          <TooltipComponent content="Profile" position="BottomCenter">
            <div
              className="flex items-center gap-4 mx-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
              onClick={() => handleClick("userProfile")}
            >
              <img className="rounded-full w-8 h-8" src={data?.profilePicture || avatar} alt={data?.profilePicture || avatar} />
              <p>
                <span className="text-gray-400 text-14">Hi,</span>{" "}
                {console.log(data)}
                <span className="text-gray-400 font-bold text-14">
                  {data?.name?.split(" ")?.[0] || "User"}
                </span>
              </p>
              <MdKeyboardArrowDown className="text-gray-400 text-14" />
            </div>
          </TooltipComponent>

          {isClicked.cart && <Cart />}
          {isClicked.chat && <Chat />}
          {isClicked.notification && <Notification />}
          {isClicked.userProfile && <UserProfile />}
        </div>
      </div>
    </>
  );
};

export default Navbar;
