import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'

import Button from './Button'
import { userProfileData } from '../data/dummy'
import { useStateContext } from '../contexts/ContextProvider'
import avatar from '../data/avatar.jpg'
import PorfileItem from './PorfileItem'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { LOGOUT } from '../redux/auth/authType'

const UserProfile = () => {
  const { currentColor, handleClick } = useStateContext()
  const data = useSelector((state) => (state as any)?.authReducer)
  const dispatch = useDispatch()
  const logout = async () => {
    try {
      await Cookies.remove('authToken')
      Cookies.remove('services', { path: '/' })

      dispatch({
        type: LOGOUT,
        payload: {
          token: null,
          type: null,
        },
      })
      redirect('/')
    } catch (e) {
      console.error('Error: logout', e)
    }
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 drop-shadow-md">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <button
          type="button"
          onClick={() => handleClick('userProfile', false)}
          className="text-xl rounded-full p-3 hover:bg-light-gray block"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={data?.profilePicture || avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {data?.name || 'User'}{' '}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {data?.role || data?.type || 'Administrator'}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {data?.email || '-'}
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <PorfileItem key={index} {...item} />
        ))}
      </div>
      <div className="mt-5 flex justify-center">
        <Button
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
          customclass="px-12"
          onClick={() => logout()}
        />
      </div>
    </div>
  )
}

export default UserProfile
