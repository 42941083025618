import { Avatar, Divider, Empty, Image, Rate, Steps, Tabs } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDataContext } from "../../contexts/DataContextProvider";

const ServiceDetails = ({ data: propData }: { data: any }): ReactElement => {
  const [data, setData] = useState({ loading: false, data: null });
  const { token } = useDataContext();

  function getPartners() {
    if (!propData?.id) return;

    setData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_CONVEYANCE_API}/details?conveyanceId=${propData?.id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setData({ loading: false, data: res?.data?.conveyance });
        }
      })
      .catch((err) => {
        console.error(err);
        setData({ loading: false, data: null });
      });
  }

  useEffect(() => getPartners(), [propData]);

  return (
    <div>
      <Tabs tabPosition="top">
        {/* <Tabs.TabPane tab="Personal Infomation" key="GENERAL">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-800 mb-1">Name</dt>
              <dd className="text-sm font-medium text-gray-800">
                {data?.name?.trim()}
              </dd>
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-900 mb-1">Slug</dt>
              <dd className="text-sm font-medium text-gray-500">
                {data?.slug}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Product ID
              </dt>
              <dd className="text-sm font-medium text-gray-500">{data?.id}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Barcode
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                {data?.barCode}
              </dd>
            </div>
            {data?.sku && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">SKU</dt>
                <dd className="text-sm font-medium text-gray-500">
                  {data?.sku}
                </dd>
              </div>
            )}
            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Category:
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <Avatar src={data?.category?.image} /> {data?.category?.title}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Category Slug:
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                {data?.category?.slug}
              </dd>
            </div>

            {data?.brand && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Brand:
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  <Avatar src={data?.brand?.logo} /> {data?.brand?.name}
                </dd>
              </div>
            )}

            {data?.campaign && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">
                  Campaign:
                </dt>
                <dd className="text-sm font-medium text-gray-500">
                  <Avatar src={data?.campaign?.logo} /> {data?.campaign?.name}
                </dd>
              </div>
            )}

            {data?.city && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">City</dt>
                <dd className="text-sm font-medium text-gray-500">
                  {data?.city}
                </dd>
              </div>
            )}

            {data?.zone && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">Zone</dt>
                <dd className="text-sm font-medium text-gray-500">
                  {data?.zone}
                </dd>
              </div>
            )}

            {data?.type && (
              <div>
                <dt className="text-sm font-medium text-gray-800 mb-1">Type</dt>
                <dd className="text-sm font-medium text-gray-500">
                  {data?.type}
                </dd>
              </div>
            )}

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Rattings
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <Rate value={data?.rating || 1} disabled />
                <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                  {data?.numberOfRating || 0}
                </span>
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Allow Warranty?
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${
                      data?.isAllowWarranty ? "green" : "red"
                    }-500 mr-2`}
                  ></div>{" "}
                  {data?.isAllowWarranty ? "Active" : "Inactive"}
                </div>
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Is Returnable?
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${
                      data?.isReturnable ? "green" : "red"
                    }-500 mr-2`}
                  ></div>{" "}
                  {data?.isReturnable ? "Active" : "Inactive"}
                </div>
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Exclude From COD
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${
                      data?.excludeCOD ? "green" : "red"
                    }-500 mr-2`}
                  ></div>{" "}
                  {data?.excludeCOD ? "Active" : "Inactive"}
                </div>
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Exclude From Promo
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${
                      data?.excludeFromPromo ? "green" : "red"
                    }-500 mr-2`}
                  ></div>{" "}
                  {data?.excludeFromPromo ? "Active" : "Inactive"}
                </div>
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                Booking Enabled
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${
                      data?.bookingEnabled ? "green" : "red"
                    }-500 mr-2`}
                  ></div>{" "}
                  {data?.bookingEnabled ? "Enabled" : "Disabled"}
                </div>
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-800 mb-1">
                isPopular
              </dt>
              <dd className="text-sm font-medium text-gray-500">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full bg-${
                      data?.isPopular ? "green" : "red"
                    }-500 mr-2`}
                  ></div>{" "}
                  {data?.isPopular ? "Active" : "Inactive"}
                </div>
              </dd>
            </div>
          </dl>
        </Tabs.TabPane>

        <Tabs.TabPane tab="VARIATIONS" key="VARIATIONS">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
            {data?.variations?.length ? (
              data?.variations?.map((variant: any, index: string) => (
                <>
                  <div
                    key={index}
                    className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50"
                  >
                    <div className="flex justify-start items- ncenter pb-4">
                      <Avatar
                        size={100}
                        className="bg-contain rounded-2xl"
                        src={variant?.imageUrl}
                        alt="attachment"
                      />
                    </div>

                    <div className="flex justify-between py-1">
                      <div className="font-medium">Variation Name:</div>
                      <div className="text-sm font-normal text-gray-700">
                        {variant?.variationName}
                      </div>
                    </div>

                    {variant?.barCode && (
                      <div className="flex justify-between py-1">
                        <div className="font-medium">Barcode</div>
                        <div className="text-sm font-normal text-gray-700">
                          {variant?.barCode}
                        </div>
                      </div>
                    )}

                    {variant?.sku && (
                      <div className="flex justify-between py-1">
                        <div className="font-medium">SKU</div>
                        <div className="text-sm font-normal text-gray-700">
                          {variant?.sku}
                        </div>
                      </div>
                    )}

                    <div className="flex justify-between py-1">
                      <span className="font-medium">Variation Id</span>
                      <span className="text-sm font-normal text-gray-700">
                        {variant?.variationId}
                      </span>
                    </div>

                    <div className="flex justify-between py-1">
                      <span className="font-medium">MRP</span>
                      <span className="text-sm font-normal text-gray-700">
                        {variant?.price?.mrp} {variant?.price?.currencyCode}
                      </span>
                    </div>

                    <div className="flex justify-between py-1">
                      <span className="font-medium">TP</span>
                      <span className="text-sm font-normal text-gray-700">
                        {variant?.price?.tp} {variant?.price?.currencyCode}
                      </span>
                    </div>

                    <div className="flex justify-between py-1">
                      <span className="font-medium">Stock</span>
                      <span className="text-sm font-normal text-gray-700">
                        <span
                          className={`font-bold bg-white border rounded-full py-1 px-3 ${
                            variant?.stock < 10
                              ? `text-red-600`
                              : `text-green-600`
                          }`}
                        >
                          {variant?.stock}
                        </span>
                      </span>
                    </div>

                    {variant?.price?.discountedPrice && (
                      <div className="flex justify-between py-1">
                        <span className="font-medium">Discounted Price</span>
                        <span className="text-sm font-normal text-gray-700">
                          {variant?.price?.discountedPrice}{" "}
                          {variant?.price?.currencyCode}
                        </span>
                      </div>
                    )}

                    {variant?.maximumOrderLimit && (
                      <div className="flex justify-between py-1">
                        <span className="font-medium">Maximum Order Limit</span>
                        <span className="text-sm font-normal text-gray-700">
                          {variant?.maximumOrderLimit}
                        </span>
                      </div>
                    )}

                    <Divider orientation="left">Product Discount</Divider>
                    <div className="flex justify-between py-1">
                      <span className="font-medium">Discount in Amount</span>
                      <span className="text-sm font-normal text-gray-700">
                        {variant?.productDiscount?.flat || 0}{" "}
                        {variant?.price?.currencyCode}
                      </span>
                    </div>
                    <div className="flex justify-between py-1">
                      <span className="font-medium">
                        Discount in Percentage
                      </span>
                      <span className="text-sm font-normal text-gray-700">
                        {variant?.productDiscount?.percentage || 0} {"%"}
                      </span>
                    </div>
                    {
                      // data.item?.products?.length ?
                      variant?.quantitativeProductDiscount?.freeProduct ? (
                        <div className="">
                          <h6 className="mt-5 border-bottom pb-2">
                            <b>Quantitative Product Discount:</b>
                          </h6>
                          <div className="flex justify-between py-1">
                            <span className="font-medium">Free Product</span>
                            <span className="text-sm font-normal text-gray-700">
                              {
                                variant?.quantitativeProductDiscount
                                  ?.freeProduct
                              }
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-medium">
                              Minimum Quantity
                            </span>
                            <span className="text-sm font-normal text-gray-700">
                              {
                                variant?.quantitativeProductDiscount
                                  ?.minimumQuantity
                              }
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-medium">
                              Product Discount in Amount
                            </span>
                            <span className="text-sm font-normal text-gray-700">
                              {
                                variant?.quantitativeProductDiscount
                                  ?.productDiscount?.flat
                              }
                            </span>
                          </div>
                          <div className="flex justify-between py-1">
                            <span className="font-medium">
                              Product Discount in Percent
                            </span>
                            <span className="text-sm font-normal text-gray-700">
                              {
                                variant?.quantitativeProductDiscount
                                  ?.productDiscount?.percentage
                              }
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  </div>
                </>
              ))
            ) : (
              <Empty />
            )}
          </div>
        </Tabs.TabPane>

        {data?.description && (
          <Tabs.TabPane tab="DESCRIPTION" key="DESCRIPTION">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
              <div>
                <dt className="text-md font-bold text-gray-700">Description</dt>
                <dd
                  className="mt-1 space-y-3 text-sm text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                ></dd>
              </div>
            </dl>
          </Tabs.TabPane>
        )}

        {data?.faq && (
          <Tabs.TabPane tab="FAQ" key="FAQ">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
              <div>
                <dt className="text-md font-bold text-gray-700">FAQ's</dt>
                <dd
                  className="mt-1 space-y-3 text-sm text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: data?.faq,
                  }}
                ></dd>
              </div>
            </dl>
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab="SEO" key="SEO">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
            {data?.metaTitle && (
              <div>
                <dt className="text-md font-bold text-gray-700">Meta Title</dt>
                <dd className="text-sm font-light text-gray-500">
                  {data?.metaTitle}
                </dd>
              </div>
            )}
            <div>
              <dt className="text-md font-bold text-gray-700">Meta Keyword</dt>
              <dd className="text-sm font-light text-gray-500">
                {data?.keyword}
              </dd>
            </div>
            {data?.metaDescription && (
              <div>
                <dt className="text-md font-bold text-gray-700">
                  Meta Description
                </dt>
                <dd className="text-sm font-light text-gray-500">
                  {data?.metaDescription}
                </dd>
              </div>
            )}
          </dl>
        </Tabs.TabPane>

        <Tabs.TabPane tab="LOGS" key="LOGS">
          <div className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mb-10">
            {data?.createdBy && (
              <div className="rounded-md border text-center p-4 max-w-5xl">
                <div className="text-lg font-bold text-gray-700">
                  Created By
                </div>
                <Avatar
                  src={data?.createdBy?.profilePicture}
                  size={80}
                  className="shadow-md border my-4"
                />
                <div className="text-sm font-semibold text-gray-500">
                  {data?.createdBy?.name}
                </div>
                <div className="text-sm font-light text-gray-500">
                  {data?.createdBy?.mobileNumber?.replace("+88", "")}
                </div>
              </div>
            )}

            {data?.updatedBy && (
              <div className="rounded-md border text-center p-4 max-w-5xl">
                <div className="text-lg font-bold text-gray-700">
                  Updated By
                </div>
                <Avatar
                  src={data?.updatedBy?.profilePicture}
                  size={80}
                  className="shadow-md border my-4"
                />
                <div className="text-sm font-semibold text-gray-500">
                  {data?.updatedBy?.name}
                </div>
                <div className="text-sm font-light text-gray-500">
                  {data?.updatedBy?.mobileNumber}
                </div>
              </div>
            )}

            {data?.approvedBy && (
              <div className="rounded-md border text-center p-4 max-w-5xl">
                <div className="text-lg font-bold text-gray-700">
                  Approved By
                </div>
                <Avatar
                  src={data?.approvedBy?.profilePicture}
                  size={80}
                  className="shadow-md border my-4"
                />
                <div className="text-sm font-semibold text-gray-500">
                  {data?.approvedBy?.name}
                </div>
                <div className="text-sm font-light text-gray-500">
                  {data?.approvedBy?.mobileNumber}
                </div>
              </div>
            )}
          </div>
          {data?.updatedLogs && (
            <>
              <h4 className="mb-4 font-bold text-lg text-sky-900 border-b pb-2 ">
                Updated Logs
              </h4>
              <Steps direction="vertical" size="default">
                {data?.updatedLogs?.map((data: any, i: string) => (
                  <Steps.Step
                    key={i}
                    icon={
                      <Avatar
                        src={data?.updatedBy?.profilePicture}
                        className="border shadow-md"
                      />
                    }
                    title={data?.updatedBy?.name}
                    subTitle={moment(data?.updatedAt).format("lll")}
                    description={data?.updatedBy?.mobileNumber}
                  />
                ))}
              </Steps>
            </>
          )}
        </Tabs.TabPane>
       */}
      </Tabs>
    </div>
  );
};

export default ServiceDetails;
