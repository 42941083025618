import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Search,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

import { employeesData, employeesGrid } from "../data/dummy";
import { Header } from "../components";
import Layout from "../components/Layout";
import { SiAxios } from "react-icons/si";
import axios from "axios";
import { useDataContext } from "../contexts/DataContextProvider";

const Partners = () => {
  const [data, setData] = useState({ loading: false, list: [] });
  const { token } = useDataContext();

  function getPartners() {
    setData({ loading: true, list: [] });
    axios
      .get(`${process.env.REACT_APP_PARTNER_API}/all?name=${''}&page=${0}&limit=${20}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setData({ loading: false, list: res?.data?.partners || [] });
        }
      })
      .catch((err) => {
        console.error(err);
        setData({ loading: false, list: [] });
      });
  }

  useEffect(() => getPartners(), []);

  return (
    <>
      <Layout>
        <div className="m-6 mx-8">
          <Header category="page" title="Partners" />
        </div>
        <div className="m-6 p-2 md:py-10 md:px-10 bg-white rounded-3xl">
          <GridComponent
            id="gridcomp"
            dataSource={data?.list}
            allowPaging
            allowSorting
            toolbar={["Search"]}
            width="auto"
          >
            <ColumnsDirective>
              {employeesGrid?.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Search, Toolbar]} />
          </GridComponent>
        </div>
      </Layout>
    </>
  );
};

export default Partners;
