import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Search,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

import { employeesData, employeesGrid } from "../data/dummy";
import { Header } from "../components";
import Layout from "../components/Layout";
import { SiAxios } from "react-icons/si";
import axios from "axios";
import { useDataContext } from "../contexts/DataContextProvider";
import ServicesTable from "../components/Services";

const Services = () => {
  const [data, setData] = useState({ loading: false, list: [] });

  return (
    <>
      <Layout>
        <div className="m-6 mx-8">
          <Header category="page" title="Service Requests" />
        </div>
        <div className="m-6 p-2 md:py-10 md:px-10 bg-white rounded-3xl">
          <div>
            <ServicesTable />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Services;
