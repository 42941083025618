import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Search,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Input,
  Layout,
  message,
  Rate,
  Tag,
} from "antd";
import axios from "axios";
import { Header } from "..";
import { useDataContext } from "../../contexts/DataContextProvider";
import { employeesGrid } from "../../data/dummy";
import styles from "./Service.module.css";
import { FiEyeOff } from "react-icons/fi";
import moment from "moment";
import PaginationC from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router-dom";
import ServiceDetails from "./ServiceDetails";
import { GiEyedropper } from "react-icons/gi";
import { BsEyeFill } from "react-icons/bs";
import { HiEye } from "react-icons/hi";
import { RiEye2Line } from "react-icons/ri";
import { BiCheck } from "react-icons/bi";
import _ from "lodash";

const ServicesTable = () => {
  const [data, setData] = useState({ loading: false, data: null });
  const { token } = useDataContext();
  const loc = useLocation();
  const limit = getParamValue(loc.search, "limit") || 16;
  const page = getParamValue(loc.search, "page") || 0;
  const [selection, setSelection] = useState();
  const [name, setName] = useState("");

  function getPartners(stopLoading?: boolean) {
    if (!stopLoading) setData({ loading: true, data: data });
    axios
      .get(
        `${
          process.env.REACT_APP_PARTNER_API
        }/service-history?status=PENDING&name=${
          name || ""
        }&page=${page}&limit=${limit}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setData({ loading: false, data: res?.data });
        }
      })
      .catch((err) => {
        console.error(err);
        setData({ loading: false, data: null });
      });
  }

  function acceptService(pid, sn) {
    axios
      .put(
        `${process.env.REACT_APP_PARTNER_API}/verify`,
        {
          partnerId: pid,
          serviceName: sn,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          message.success("Service accepted!");
          getPartners(true);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Service is not accepted!");
      });
  }

  useEffect(() => getPartners(), [name, page, limit]);

  return (
    <>
      <div className="bg-white rounded-3xl">
        <div>
          <div className={styles.contentWrapper}>
            <div className="overflow-x-auto">
              <div className="min-w-full">
                <div className="border-b bg-gray-100/50 px-4 py-2 flex justify-end">
                  <div>
                    <Input
                      className="border-none bg-transparent focus:bg-white"
                      style={{ width: 200 }}
                      placeholder="Search"
                      onChange={(e) => {
                        setName(e.target?.value || "");
                      }}
                    />
                  </div>
                </div>
                <div className={`content-body`}>
                  {data?.loading ? (
                    <div className="p-4">Loading...</div>
                  ) : (
                    <table className={styles.table}>
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col">Partner</th>
                          <th scope="col">Service</th>
                          <th scope="col">Status</th>
                          <th scope="col">Created</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.data?.partnerServices?.length ? (
                          data?.data?.partnerServices?.map(
                            (product: any, index: any) => (
                              <tr
                                className="border-t hover:bg-gray-100"
                                key={index}
                              >
                                <td>
                                  <div className="checkbox-large flex items-center gap-2">
                                    <Avatar
                                      size={45}
                                      src={product?.productImage}
                                      shape="square"
                                    />
                                    <div className="flex flex-col">
                                      <span className="font-medium text-gray-500">
                                        {product?.partner?.name?.slice(0, 50)}
                                      </span>
                                      {product?.partner && (
                                        <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                          {product?.partner?.mobileNumber}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <span className="font-medium text-gray-500 ml-2">
                                    {product?.serviceName}
                                  </span>
                                </td>
                                {/* <td>
                                  <Rate value={product?.rating || 1} disabled />{" "}
                                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                    {product?.numberOfRating || 0}
                                  </span>
                                </td> */}

                                <td>
                                  <Tag
                                    color={
                                      {
                                        PENDING: "gold",
                                        REQUSETED: "lime",
                                        PROCESSING: "magenta",
                                        COMPLETE: "green",
                                      }[product?.status]
                                    }
                                  >
                                    {product?.status}
                                  </Tag>
                                </td>

                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {product?.createdBy?.name ||
                                      product?.createdBy?.mobileNumber}{" "}
                                    -{" "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        product?.createdAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(product?.createdAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(product?.createdAt).format("lll")}
                                  </span>
                                </td>

                                <td>
                                  <div className="flex flex-grow">
                                    <Button
                                      shape="round"
                                      type="dashed"
                                      className="bg-white mr-1"
                                      onClick={() => setSelection(product)}
                                    >
                                      <RiEye2Line />
                                    </Button>
                                    <Button
                                      shape="round"
                                      type="dashed"
                                      className="bg-white mr-1"
                                      onClick={() =>
                                        acceptService(
                                          product?.partner?.id,
                                          product?.serviceName
                                        )
                                      }
                                    >
                                      <BiCheck />
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={9}>
                              <div className="py-0 px-2">
                                No record to display
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>

            <PaginationC
              {...data?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
        </div>
      </div>
      <Drawer
        title={"Details"}
        placement="right"
        onClose={() => setSelection(undefined)}
        open={selection}
        width={650}
      >
        <ServiceDetails data={selection} />
      </Drawer>
    </>
  );
};

export default ServicesTable;
