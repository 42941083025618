import Cookies from "js-cookie";
import { responseNotification } from "../../utils/notify";
import { LOGIN_REQUEST, LOGIN_SUCCESS } from "./authType";
import decode from "jwt-decode";

export const login = async (
  dispatch: (arg0: { type: string; payload?: any }) => void,
  { email, password, type }: any
) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const res = await fetch(`${process.env.REACT_APP_AUTH_API}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const response1 = await res.json();
    const { token } = response1;

    if (token) {
      Cookies.set("authToken", token, {
        secure: true,
        expires: (decode(token as string) as any)?.exp,
      });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          ...response1,
          iat: new Date(),
          // type: type,
        },
      });

      responseNotification(
        response1?.message?.replace("Operation", "Login"),
        "success"
      );

      return true;
    } else {
      responseNotification(
        response1?.message || "Login failed",
        "error"
      );
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
