import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { login } from "../redux/auth/authAction";

const Login = () => {
  const { currentColor } = useStateContext();
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const emailData = data?.email;
    const passwordData = data?.password;
    setLoading(true);
    const res = await login(dispatch, {
      email: emailData,
      password: passwordData,
      type: type,
    });
    setLoading(false);
    if (res) {
      navigate("/");
    }
  };

  return (
    <div className="rounded-lgx">
      <Form onFinish={onSubmit} layout="vertical" form={form} size="large">
        <div className="h-screen overflow-hidden bg-primary flex justify-center items-center">
          <div className="absolute shadow-lg w-60 h-60 rounded-xl bg-secondary -top-5 -left-16 z-0 transform rotate-45 hidden md:block"></div>
          <div className="absolute shadow-lg w-48 h-48 rounded-xl bg-secondary bottom-10 right-10 transform rotate-12 hidden md:block"></div>
          <div className="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
            <div>
              <h1 className="text-3xl font-bold text-center mb-4 cursor-pointer">
                Login Your Account
              </h1>
              <p className="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">
                Enter your account to enjoy all the services without any extra
                effort!
              </p>
            </div>
            <div className="space-y-4">
              <Form.Item
                label=""
                id="email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required!",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Email Addres"
                  className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
                />
              </Form.Item>
              <Form.Item
                label=""
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (names?.length < 6) {
                        return Promise.reject(
                          new Error("Password min 6 digit")
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Password"
                  className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
                />
              </Form.Item>
            </div>
            <div className="text-center mt-6">
              <button className="py-3 w-64 text-xl text-white bg-primary rounded-2xl">
                Login
              </button>
              {/* <p className="mt-4 text-sm">
                Already Have An Account?{" "}
                <span className="underline  cursor-pointer"> Sign In</span>
              </p> */}
            </div>
          </div>
          <div className="w-40 h-40 shadow-lg absolute bg-secondary rounded-full top-0 right-12 hidden md:block"></div>
          <div className="w-20 h-40 shadow-lg absolute bg-secondary rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
        </div>
      </Form>
    </div>
  );
};

export default Login;
