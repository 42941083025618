import React, { createContext, useContext, useEffect, useState } from "react";
import { store } from "../redux/store";
import { Provider, useSelector } from "react-redux";

const StateContext = createContext(null);

export const DataContextProvider = ({ children }: any) => {
  const data = useSelector((state) => (state as any)?.authReducer);

  return (
    <div>
      <StateContext.Provider value={data}>{children}</StateContext.Provider>
    </div>
  );
};

export const useDataContext = () => useContext(StateContext);
