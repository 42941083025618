import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";

import { customersData, customersGrid } from "../data/dummy";

import { Header } from "../components";
import Layout from "../components/Layout";

const Customers = () => {
  return (
    <Layout>
      <div className="m-6 mx-8">
        <Header category="page" title="Customers" />
      </div>
      <div className="m-2 md:mx-6 md:my-y p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          id="gridcomp"
          dataSource={customersData}
          allowPaging
          allowSorting
          toolbar={["Search", "Delete"]}
          editSettings={{ allowDeleting: true, allowEditing: true }}
          width="auto"
        >
          <ColumnsDirective>
            {customersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Edit, Sort, Filter]} />
        </GridComponent>
      </div>
    </Layout>
  );
};

export default Customers;
